import React from "react"
import { colors } from "../../../assets/themes/default"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PostDate from "../PostDate"
import styled from "@emotion/styled"
import Text from "../Text"

const PostPreview = ({ title, content, publishDate, url, image, logo }) => {
  const styleWrapper = {
    backgroundColor: `${colors.white}`,
    boxShadow: `0px 20px 50px rgba(0, 0, 0, 0.5)`,
    flexBasis: `33%`,
    flexGrow: 1,
    flexShrink: 1,
    margin: `25px`,
    maxWidth: `350px`,
    minHeight: `540px`,
    minWidth: `350px`,
  }

  const styleHeader = {
    alignItems: `center`,
    backgroundColor: `${colors.white}`,
    backgroundPosition: `center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    display: `flex`,
    height: `240px`,
    justifyContent: `center`,
  }

  const styleLogo = {
    maxWidth: `50%`,
  }

  const styleContent = {
    minHeight: `300px`,
    padding: `10px 20px 10px 20px`,
    textAlign: `center`,
  }

  const styleLink = {
    color: colors.textDark,
    textDecoration: `none`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const Header = styled.div`
    ${styleHeader}
  `

  const Logo = styled(Img)`
    ${styleLogo}
  `

  const Content = styled.div`
    ${styleContent}
  `

  const StyledLink = styled(Link)`
    ${styleLink}
  `

  return (
    <Wrapper>
      <StyledLink to={url}>
        <Header>
          {image && (
            <Img
              fluid={image.fluid}
              style={{ height: "240px", width: "350px" }}
            ></Img>
          )}
          {logo && <Logo fluid={logo.fluid} style={{ width: "100%" }}></Logo>}
        </Header>
        <Content>
          <PostDate date={publishDate} />
          <Text type="subsubtitle">{title}</Text>
          <div style={{ textAlign: `left` }}>
            <Text type="small" color={colors.grayDark}>
              {content}
            </Text>
          </div>
          <Text>Read more</Text>
        </Content>
      </StyledLink>
    </Wrapper>
  )
}

export default PostPreview
