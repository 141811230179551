import React from "react"
import styled from "@emotion/styled"
import Icon from "../Icon"
import Text from "../Text"
import { iconSizes, colors } from "../../../assets/themes/default"

const IconLabel = ({ icon, color, children, ...rest }) => {
  const styleWrapper = {}

  const styleText = {
    display: `inline-block`,
    margin: `5px 0 5px 0px`,
  }

  const styleIcon = {
    marginRight: `0.5rem`,
    verticalAlign: `text-bottom`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const StyledIcon = styled(Icon)`
    ${styleIcon}
  `

  const StyledText = styled(Text)`
    ${styleText}
  `

  return (
    <Wrapper {...rest}>
      <StyledIcon name={icon} color={color} size={iconSizes.small} />
      <StyledText color={colors}>{children}</StyledText>
    </Wrapper>
  )
}

export default IconLabel
