import React from "react"
import { formattedDateFromString } from "../../../assets/themes/default"
import IconLabel from "../IconLabel"

const PostDate = ({ date }) => {
  return (
    <IconLabel icon="clock-dark">{formattedDateFromString(date)}</IconLabel>
  )
}

export default PostDate
